import FreemiumBannerResponsiveComponent from '@wix/thunderbolt-elements/src/components/FreemiumBanner/FreemiumBannerResponsive/viewer/FreemiumBannerResponsive';


const FreemiumBannerResponsive = {
  component: FreemiumBannerResponsiveComponent
};


export const components = {
  ['FreemiumBannerResponsive']: FreemiumBannerResponsive
};

